* {
  box-sizing: border-box;
}

.Gallery {
  display: flex;
  align-items: center;
  padding-top: 1vw;
  padding-bottom: 1vw;
  overflow-x: auto;
  height: 80vh;
  direction: ltr;
  overflow-y: hidden;
}

.Gallery-title {
  font-family: 'Butterfly', serif;
  font-size: 5em;
  z-index: 1;
  position: absolute;
  top: 68px;
  left: 34px;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 3px 1px 0 white,
    3px 2px 4px rgba(0, 0, 0, 0.3);
  color: antiquewhite;
}

.Gallery::-webkit-scrollbar {
  height: 10px;
}

.Gallery::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
}

.Gallery-img-parent {
  min-width: 200px;
  border-radius: 32px;
  margin-left: 24px;
  padding: 6px;
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.Gallery-img-child {
  border-radius: 32px;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  opacity: 0.9;
  overflow: hidden;
  transition: all 0.7s ease;
  background-color: white;
}

.Gallery-img-date {
  position: absolute;
  text-align: center;
  width: 100%;
  top: -2em;
}

.Gallery-img-date-in {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Raleway', sans-serif;
  background-color: rgba(256, 256, 256, 0.8);
  border-radius: 19px;
  padding: 4px 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.Gallery-img-name {
  font-family: 'Butterfly', serif;
  position: absolute;
  bottom: -50px;
  left: 0px;
  z-index: 1;
  text-align: center;
  width: 100%;
  font-size: 30px;
  line-height: 100%;
  text-shadow: 2px 0px white, 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.Gallery-img-parent:hover,
.Gallery-img-parent:focus,
.Gallery-img-parent:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 10px 20px 0,
    10px 21px 2px rgba(0, 0, 0, 0.2); /*si non renseignee, la couleur de l'ombre est portee par le "color" de img-parent*/
  transform: scale(1.02);
}

.Gallery-placeholder {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: 'Raleway', sans-serif;
}

.Gallery-placeholder span {
  margin-top: 20px;
}

@media screen and (min-width: 650px) {
  .Gallery-img-parent {
    min-width: 200px;
  }
  .Gallery-title {
    font-size: 100px;
    top: 85px;
    left: 100px;
  }
  .Gallery-img-name {
    bottom: -70px;
  }
}

@media screen and (min-width: 1000px) {
  .Gallery-img-parent {
    min-width: 300px;
  }
  .Gallery-img-title {
    left: 20%;
    margin-right: 30%;
  }
  .Gallery-img-name {
    font-size: 3em;
  }
}
