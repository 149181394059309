* {
  box-sizing: border-box;
}

.Focus {
  display: flex;
  height: calc(100vh - 102px - 40px); /*header : 68px, footer : 34px */
  width: 100vw;
  position: relative;
  overflow: hidden;
}

.Focus-openTextPanel {
  z-index: 4;
  position: absolute;
  cursor: pointer;
  font-family: Arial, sans-serif;
  color: #f8f8f8;
  font-size: 7em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 3px 2px 0 #4e4e4e,
    1px 2px 4px rgba(0, 0, 0, 0.3);
  top: 40px;
  left: 20px;
  transition: all 0.4s ease-in-out;
}
.Focus-openTextPanel:hover {
  transform: scale(1.02);
}

.Focus-textPanel {
  position: absolute;
  left: 5%;
  top: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1;
  width: 60vw;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 10px 10px 0, 10px 11px 2px grey;
  border: 1px solid cornflowerblue;
  padding: 0.8rem 1.5rem;
  transition: all 0.4s linear;
}

.Focus-textPanel span {
  font-family: 'Butterfly', serif;
  color: white;
  font-size: 1.5em;
  border-radius: 4px;
  padding: 0.2em 0.8em;
  margin: -1.6em;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.Focus-textPanel h2 {
  color: #4e4e4e;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 1.4em;
  margin-block-end: 0;
  margin-block-start: 0.4rem;
}

.Focus-textPanel h1 {
  font-family: 'Butterfly', serif;
  font-weight: normal;
  line-height: 1.2em;
  color: #4e4e4e;
  font-size: 2em;
  margin-block-start: 0;
  margin-block-end: 0;
}

.Focus-textPanel p {
  font-family: 'Raleway', sans-serif;
  color: #4e4e4e;
  font-weight: 300;
  margin-block-start: 0.3rem;
}

.Focus-button {
  position: absolute;
  color: #f8f8f8;
  z-index: 1;
}

.Focus-prev-next {
  z-index: 2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 200px;
  font-weight: 200;
  text-shadow: 0 1px 2px grey;
  opacity: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

.Focus-next {
  right: 0;
}

.Focus-prev {
  left: 0;
}

.Focus-imagePanel {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Focus-img {
  max-width: 95%;
  max-height: 95%;
  overflow: hidden;
  box-shadow: 1px 2px 3px grey;
  border-radius: 20px;
}

.Focus-imagePanel::-webkit-scrollbar {
  height: 10px;
}

.Focus-imagePanel::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1em;
}

.Focus-transition-opacity-enter {
  opacity: 0;
  content: 'Chargement en cours...';
  font-family: 'Raleway', sans-serif;
}
.Focus-transition-opacity-enter-active {
  opacity: 1;
  transition: all 750ms ease-in-out;
}
.Focus-transition-opacity-exit {
  opacity: 1;
}
.Focus-transition-opacity-active {
  opacity: 0;
  transition: all 750ms ease-in-out;
}

.Focus-placeholder {
  width: 100vw;
  position: absolute;
  top: 50%;
  display: flex;
  color: rgba(0, 0, 0, 0.35);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: 'Raleway', sans-serif;
}

.Focus-placeholder span {
  margin-bottom: 35px;
}

@media screen and (min-width: 500px) {
  .Focus-textPanel {
    width: 35vw;
  }
  .Focus-prev-next:hover {
    opacity: 1;
  }
}

@media screen and (min-width: 1025px) {
  .Focus-textPanel {
    width: 30vw;
  }
  .Focus-img {
    border-radius: 3%;
  }
}
