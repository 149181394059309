* {
  box-sizing: border-box;
}

.CloseButton {
  position: absolute;
  color: #f8f8f8;
  z-index: 2;
  font-size: 4em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  top: 68px;
  right: 34px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.CloseButton:hover {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
