* {
  box-sizing: border-box;
}

.About {
  font-family: 'Raleway', sans-serif;
  height: calc(100vh - 10em);
  position: relative;
}

.About-content {
  margin: 2em;
  width: 90vw;
}

.About-content p {
  font-size: 1.5em;
}

.About-img {
  height: 20vh;
  width: 20vh;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.About-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.About-shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: inset 2px 2px 5px #a98c8c;
}

.About-contact:before {
  content: 'Contact : ';
  font-weight: bold;
}

@media screen and (min-width: 1300px) {
  .About-content {
    width: 50vw;
  }
}
