* {
  box-sizing: border-box;
}

.MonSite {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f3f6;
  font-size: calc(10px + 1vmin);
  color: #4e4e4e;
  position: relative;
}

.MonSite-header {
  font-family: 'Raleway', sans-serif;
  height: 68px;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
}

.MonSite-header span {
  margin: 1rem 2rem 0.5rem 2rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.4s linear;
  border-bottom: 1px solid transparent;
}

.MonSite-header span:hover {
  color: #638edb;
}

.MonSite-Delphine {
  font-family: 'Butterfly', serif;
  font-size: 2em;
}

.MonSite-footer {
  height: 34px;
  font-size: smaller;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  justify-content: center;
}
